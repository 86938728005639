<script setup lang="ts">
import type { IconName } from '#build/icons'

withDefaults(
  defineProps<{
    title: string
    description?: string
    button?: string
    to?: string
    icon?: IconName
  }>(),
  {
    description: undefined,
    button: undefined,
    to: undefined,
    icon: 'empty-folder',
  },
)

const emit = defineEmits<{
  click: [e: Event]
}>()
</script>

<template>
  <div class="mt-16 flex w-full flex-col items-center justify-between gap-8">
    <span class="text-center font-bold">{{ title }}</span>

    <Icon
      v-if="icon"
      :name="icon"
      class="mx-auto flex justify-center text-8xl text-gray-200"
    />

    <slot>
      <span v-if="description" class="max-w-md text-center font-normal">
        {{ description }}
      </span>
    </slot>

    <Button v-if="button && to" size="sm" color="primary" :to="to">
      {{ button }}
    </Button>

    <Button
      v-else-if="button"
      size="sm"
      color="primary"
      @click="emit('click', $event)"
    >
      {{ button }}
    </Button>
  </div>
</template>
